<!-- 
#SBLI needs to have the agent code variable added to the link
#Needs cookie set for JIT clicks
#Cookie based v-if for the dialog still needed
-->
<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0" style="max-width:1400px; margin:0 auto;">
        <template>
            <v-row class="pt-8 px-8">
                <div>
                    <h2>Carrier E-Apps</h2>
                    <p>Please click on the carrier below to access their e-app platform/software.</p>
                    <p class="body-2">
						<v-icon color="#46c3b2">fa-regular fa-bookmark</v-icon>
						<span class="ml-2">Just-In-Time</span>
						<v-tooltip bottom>
							<template #activator="{ on, attrs }">
								<v-btn class="ml-2" icon x-small v-bind="attrs" v-on="on">
									<v-icon>fas fa-info-circle</v-icon>
								</v-btn>
							</template>
							<p class="mb-0" style="max-width:20rem;">{{ JITDefinition }}</p>
						</v-tooltip>
					</p>
                </div>
            </v-row>
            <v-row class="pb-8 px-8">
                <v-btn
                    href="https://dynamic-advisor-prod.techf.com/login?org=org_VXB5UCMciyy4LpyU&connection=QuilitySSO"
                    color="q_blue_1"
                    dark
                    depressed
                    large
                    target="_blank"
                    class="ml-auto"
                    >Go to Navigator
                </v-btn>
            </v-row>
            <v-row class="px-5 justify-center">
                <v-card v-for="(carrierEapp, ix) in carrierEappsSorted" :key="ix" width="374"
                    :class="' ma-5 pa-4 ' + (carrierEapp.justInTime ? 'just_in_time' : '')">
                    <v-img max-width="250" max-height="150" class="center-image" :src="carrierEapp.logoImage"></v-img>
                    <v-card-text v-if="carrierEapp.jitInstructionsLabel">
                        <a :href="carrierEapp.jitInstructionsLink">
                            <strong class="app-message">{{ carrierEapp.jitInstructionsLabel }}</strong>
                        </a>
                    </v-card-text>
                    <v-card-actions class="mt-5">
                        <v-btn v-if="carrierEapp.justInTime && !hasCookieSet(carrierEapp.cookieKey)" color="q_blue_1"
                            dark right @click="showCarrierDialog(carrierEapp)" depressed target="_blank" bottom
                            absolute>Access</v-btn>
                        <v-btn v-else color="q_blue_1" dark right :href="(carrierEapp.appLink)" depressed
                            target="_blank" bottom absolute>Access</v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>

            <v-dialog v-model="showDialog" max-width="600">
                <v-card>
                    <v-toolbar color="warning" dark>
                        New Agent Alert
					</v-toolbar>
                    <v-card-text v-if="currentCarrier.jitDialogMessage">
						<div v-if="hasCMSToken(currentCarrier.jitDialogMessage)" class="pa-12">
							<v-progress-linear v-if="cmsLoading" :height="2" indeterminate />
							<div v-else-if="cmsError" class="text-h2">
								Oops! Failed to load description of "JIT instructions" from cms page
							</div>
							<div v-else-if="cmsBlock" v-html="cmsBlock" />
						</div>
                        <div v-else class="text-h2 pa-12">
							<template v-if="Array.isArray(currentCarrier.jitDialogMessage)">
								<p v-for="message in currentCarrier.jitDialogMessage" :key="message">
									{{ message }}
								</p>
							</template>
							<p v-if="typeof currentCarrier.jitDialogMessage === 'string'">
								{{ currentCarrier.jitDialogMessage }}
							</p>
						</div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn depressed dark color="q_blue_1" :disabled="cmsLoading" @click="setCookieAndVisitCarrier">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </v-container>
</template>

<script>
import Cookies from 'js-cookie'

import Page from '@/store/Models/Page'
import QuilityAPI from '@/store/API/QuilityAPI'

const CMS_TOKEN_REGEXP = /\[CMS_PAGE\]\((.*?)\)/

export const hasCMSToken = (str) => {
  return CMS_TOKEN_REGEXP.test(str)
}

export const parseCmsToken = (str) => {
  const cmsToken = str.match(CMS_TOKEN_REGEXP)?.[1]
  if (cmsToken) {
	const [slug, title] = cmsToken.split('::')
    return { slug, title }
  }
  return { slug: '', title: '' }
}

export default {
    data: function () {
        return {
            showDialog: false,
            currentCarrier: {},

			cmsLoading: false,
			cmsError: false,
			cmsBlock: null,

			JITDefinition: 'Just-In-Time (JIT) allows newly licensed agents to serve clients faster by not having to wait for an agent writing number for specified products with JIT carriers. For pre-appointment carriers, an agent must be appointed (have writing number) with a carrier prior to soliciting that carrier\'s products.',
			JITInstructions: 'JIT Instructions',
        }
    },
    computed: {
        carrierEapps () {
            return [
                {
                    carrier: "AmAm",
                    logoImage: "https://hq-image-cdn.azureedge.net/servers/public/cms_images/AmAm-Logo_American-Amicable_21-03-11_800x800_JAyeN2.png",
                    appLink: "https://www.insuranceapplication.com/cgi/webappmobile/login.aspx?ReturnUrl=%2fcgi%2fwebappmobile%2f",
                    justInTime: true,
                    jitInstructionsLabel: this.JITInstructions,
                    jitInstructionsLink: "/cms/document/view/166075",
					jitDialogMessage: '[CMS_PAGE](american-amicable::Just-In-Time Carrier)',
                    cookieKey: "AmAmShown"
                },
                {
                    carrier: "Moo",
                    logoImage: "https://hq-image-cdn.azureedge.net/servers/public/cms_images/MOO_Logo_Mutual-of-Omaha_21-03-11_800x800_7vL0SE.svg",
                    appLink: "https://auth.quility.com/samlp/IAIG38uVluNjOS7x5IHPvOUYP2TzcwWv?RelayState=https://pipepasstoigo.ipipeline.com/default.aspx?gaid=6503",
					justInTime: true,
					jitInstructionsLabel: this.JITInstructions,
					jitInstructionsLink: "https://hq.quility.com/api/public/document/158044/view/1699302437/2086287a3f5c2aad71266a0904bc70aa",
					jitDialogMessage: '[CMS_PAGE](mutual-of-omaha::Just-In-Time Carrier)',
                    cookieKey: "MooShown",
                },
                {
                    carrier: "Americo",
                    logoImage: "https://hq-image-cdn.azureedge.net/servers/public/cms_images/Americo-Logo_Americo_21-03-11_800x800_ADkslC.svg",
                    appLink: "https://tools.americoagent.com",
                },
                {
                    carrier: "Foresters",
                    logoImage: "https://hq-image-cdn.azureedge.net/servers/public/cms_images/Foresters_Logo_Foresters_21-03-11_800x800_jeStXK.svg",
                    appLink: "https://auth.quility.com/samlp/IAIG38uVluNjOS7x5IHPvOUYP2TzcwWv?RelayState=https://pipepasstoigo.ipipeline.com/default.aspx?gaid=6503",
					justInTime: true,
                    jitInstructionsLabel: this.JITInstructions,
					jitInstructionsLink: "/cms/document/view/246023 ",
					jitDialogMessage: '[CMS_PAGE](foresters::Just-In-Time Carrier)',
                    cookieKey: "ForestersShown",
                },
                {
                    carrier: "JH",
                    logoImage: "https://hq-image-cdn.azureedge.net/servers/public/cms_images/JH_Logo_John-Hancock_21-03-11_800x800_RRSj2R.svg",
                    appLink: "https://instant-apply.johnhancockinsurance.com/intake-brokerage?cid=US_JH_IN_IR_mm_mm_Vitality_BR_CS_LK_00_00_00_00_AW_00_00_homeheader_LauncheApp",
                },
                {
                    carrier: "NLG",
                    logoImage: "https://hq-image-cdn.azureedge.net/servers/public/cms_images/NLG_Logo_National-Life-Group_21-03-11_800x800_epQ99l.svg",
                    appLink: "https://nlg-prod.auth0.com/samlp/mI5w1IaUJlcACje72K9NVFxv9xAgmUtm",
                },
                {
                    carrier: "SBLI",
                    logoImage: "https://hq-image-cdn.azureedge.net/servers/production/cms_images/SBLI%20Logo%20Small_uhlf0S.png",
                    appLink: "https://dynamic-advisor-prod.techf.com/login?org=org_VXB5UCMciyy4LpyU&connection=QuilitySSO",
                },
                {
                    carrier: "United Home Life",
                    logoImage: "https://hq-image-cdn.azureedge.net/servers/public/cms_images/UHL_Logo_United-Home-Life_21-03-11_800x800_czfRVE.svg",
                    appLink: "https://auth.quility.com/samlp/IAIG38uVluNjOS7x5IHPvOUYP2TzcwWv?RelayState=https://pipepasstoigo.ipipeline.com/default.aspx?gaid=6503",
                },
                {
                    carrier: "Legal and General",
                    logoImage: "https://hq-image-cdn.azureedge.net/servers/production/cms_images/978a842c-2156-46f6-b307-f37a015bd41b_U9mVef.png",
                    appLink: "https://dynamic-advisor-prod.techf.com/login?org=org_VXB5UCMciyy4LpyU&connection=QuilitySSO",
                },
            ]
        },
		carrierEappsSorted () {
			const jitCarriers = this.carrierEapps
				.filter((carrier) => carrier.justInTime)
				.sort((a, b) => a.carrier.localeCompare(b.carrier))
			const restCarriers = this.carrierEapps
				.filter((carrier) => !carrier.justInTime)
				.sort((a, b) => a.carrier.localeCompare(b.carrier))
			return [...jitCarriers, ...restCarriers]
		},
    },
	watch: {
		showDialog (val) {
			if (!val) {
                this.cmsLoading = false
                this.cmsError = false
                this.cmsBlock = null
                this.currentCarrier = {}
            }
		},
	},
    methods: {
		hasCMSToken,

        setCookieAndVisitCarrier: function () {
			if (!this.cmsError) {
				Cookies.set(this.currentCarrier.cookieKey, 'true', { expires: 365, path: '' })
			}
            //send the user to the carrier in a new tab. 
            window.open(this.currentCarrier.appLink)
            //then close the dialog. 
            this.showDialog = false;
        },
        showCarrierDialog(carrierEapp) {
            this.currentCarrier = carrierEapp;
            this.checkAndLoadCMS(carrierEapp)
            this.showDialog = true;
        },
        hasCookieSet(key) {
            return !!Cookies.get(key)
        },
        checkAndLoadCMS (carrierEapp) {
            if (!this.hasCMSToken(carrierEapp.jitDialogMessage)) { return }
			const { slug, title } = parseCmsToken(carrierEapp.jitDialogMessage)
			this.loadCmsBlock(slug, title)
        },
		async loadCmsBlock (slug, blockTitle) {
			this.cmsBlock = null
			this.cmsError = false
			// check store
			let page = Page.query().where('slug', slug).first()
			if (page) {
				this.cmsBlock = this.getPageBlock(page.blocks, blockTitle)
				return Promise.resolve()
			}
			// load from API
			this.cmsLoading = true
			await QuilityAPI.getPageWithSlug(slug)
			page = Page.query().where('slug', slug).first()
			if (page) {
				this.cmsBlock = this.getPageBlock(page.blocks, blockTitle)
			} else {
				this.cmsError = true
				this.showError(`Oops! There was a problem loading the cms page with slug ${slug}.`)
			}
			this.cmsLoading = false
		},
		getPageBlock (pageBlocks, blockTitle) {
			if (!pageBlocks || !Array.isArray(pageBlocks)) { return }
			// search block by title
			const blockByTitle = pageBlocks.find((block) => block?.Title?.trim?.().toLowerCase() === blockTitle.toLowerCase())
			if (blockByTitle) { return blockByTitle.text }
			// search block by content
			const regex = new RegExp(`^<.*?>${blockTitle}</.*?>`)
			const blockByContent = pageBlocks.find((block) => regex.test(block.text))
			if (blockByContent) { return blockByContent.text }
			// else
			this.cmsError = true
			this.showError(`Oops! There is no block with title ${blockTitle} on the cms page.`)
		},
    },
}
</script>

<style lang="scss" scoped>
.just_in_time {
	&::before {
		content: "\f02e";
		display: inline-block;
		position: relative;
		top: -23px;
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		color: #46c3b2;
		font-size: 24px;
	}
}

.app-message {
    font-size: 1.1rem;
}
</style>
